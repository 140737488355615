.card-content-subheader {
  width: 100%;
  flex: 1;
  .number {
    padding-right: 12px;
  }
  .core-list.subheader-set {
    padding-bottom: unset;
  }
}

.input-req {
  display: flex;
  width: 100%;
  .MuiInputBase-input {
    padding: 4px 8px;
    text-align: right;
    font-size: 12px;
    border: 1.5px solid #0019d8;
    border-radius: 3px;
  }

  .MuiInputBase-root {
    width: 70px;
    margin: 0px 0px 0px auto;
  }
  .autocomplete-uom {
    .MuiAutocomplete-endAdornment {
      display: none;
    }
  }
}

.select-req-item {
  display: flex;
  width: 100%;
  .select-field {
    display: flex;
    flex: 1;
    align-items: center;
    span {
      padding-right: 12px;
      font-size: 12px;
    }
  }
}

.md-space {
  width: 100px !important;
}

.right-space {
  width: 100px !important;
}

.empty-space {
  width: 40px;
}

.text-right {
  text-align: right;
}

.background-grey {
  background: #f2f2f2 !important;
}

.table-header {
  padding: 12px;
  background: #e5edff;
  width: 100%;
  display: flex;
}
.item-wrapper {
  // display: flex;
  width: 100%;
  padding: 12px;
  span {
    padding-right: 12px;
    font-size: 12px;
  }
}

.input-disabled {
  .MuiInputBase-root.Mui-disabled {
    color: #212121;
  }
}
