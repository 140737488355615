.core-tab {
  // position: fixed !important;
  width: 100%;
  z-index: 100;
  @media screen and (min-width: 600px) {
    margin-top: 64px;
  }
  padding: 8px 6px;

  .MuiAppBar-colorPrimary {
    color: $color-dark-blue;
    background-color: #ffffff;
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 600;
    }
    .PrivateTabIndicator-colorSecondary {
      background-color: $color-dark-blue;
    }
  }
  .tab-font {
    font-size: 0.58rem;
  }

  &.single-dynamicInfo {
    margin-top: 38px;
    @media screen and (min-width: 600px) {
      margin-top: 105px !important;
    }
  }

  &.multi-dynamicInfo {
    margin-top: 53px;
    @media screen and (min-width: 600px) {
      margin-top: 122px !important;
    }
  }
  &.multi-dynamicinfo-search {
    margin-top: 89px;
    @media screen and (min-width: 600px) {
      margin-top: 122px !important;
    }
  }
}

.MuiTabs-flexContainer {
  .MuiTab-textColorInherit.Mui-selected {
    white-space: nowrap;
    color: #212121;
  }
}

.core-tab {
  z-index: 100;
  @media screen and (min-width: 600px) {
    margin-top: 64px;
  }
  padding: 8px 6px;

  .MuiAppBar-colorPrimary {
    color: $color-dark-blue;
    background-color: #ffffff;
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 600;
    }
    .PrivateTabIndicator-colorSecondary {
      background-color: $color-dark-blue;
    }
  }
  .tab-font {
    font-size: 0.58rem;
  }

  &.single-dynamicInfo {
    margin-top: 38px;
    @media screen and (min-width: 600px) {
      margin-top: 105px !important;
    }
  }

  &.multi-dynamicInfo {
    margin-top: 53px;
    @media screen and (min-width: 600px) {
      margin-top: 122px !important;
    }
  }

  &.multi-dynamicinfo-search {
    margin-top: 89px;
    @media screen and (min-width: 600px) {
      margin-top: 157px !important;
    }
  }
}

.MuiTabs-flexContainer {
  .MuiTab-textColorInherit.Mui-selected {
    white-space: nowrap;
    color: #212121;
  }
}

// syafiq 30/6/2021
.core-tab.adjust-padding {
  padding: 0;
  .content-wrapper {
    margin-top: 84px;
  }
  @media screen and (min-width: 600px) {
    margin-top: 66px;
    .content-wrapper {
      margin-top: -3px;
    }
  }
  .MuiAppBar-colorPrimary {
    color: black;
    .MuiTab-textColorInherit {
      opacity: 1;
    }
    background-color: #ffffff;
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 400;
    }
    .PrivateTabIndicator-colorSecondary {
      background-color: $color-dark-blue;
    }
  }
  .Mui-selected {
    color: #ff9800;
    .MuiTab-textColorInherit {
      color: inherit;
      opacity: 1;
    }
    .MuiTab-wrapper {
      font-weight: 600;
    }
  }
  .responsive-tab-header {
    position: fixed;
    top: 70px;
    @media screen and (min-width: 600px) {
      position: inherit;
    }
  }
}

.core-tab.adjust-paddingV2 {
  padding: 0;
  // .content-wrapper {
  //   margin-top: 84px;
  // }
  @media screen and (min-width: 600px) {
    margin-top: 66px;
    .content-wrapper {
      margin-top: -3px;
    }
  }
  .MuiAppBar-colorPrimary {
    color: black;
    .MuiTab-textColorInherit {
      opacity: 1;
    }
    background-color: #ffffff;
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 400;
    }
    .PrivateTabIndicator-colorSecondary {
      background-color: $color-dark-blue;
    }
  }
  .Mui-selected {
    //color: #2f53e6;
    .MuiTab-textColorInherit {
      color: inherit;
      opacity: 1;
    }
    .MuiTab-wrapper {
      font-weight: 600;
      color: #ff9800;
    }
  }
  .responsive-tab-header {
    // position: fixed;
    // top: 70px;
    @media screen and (min-width: 600px) {
      position: inherit;
    }
  }
}

.tab-subheader {
  position: fixed !important;
  z-index: 9999;
  top: 118px;
  width: 100%;
  .core-list {
    padding-bottom: 0;
    .MuiListItem-root {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 600px) {
    position: inherit !important;
  }
}
