@import './module/purchase.scss';
@import './module/contract.scss';
@import './table.scss';
@import './input.scss';
@import './lists.scss';
@import './body.scss';
@import './watermark.scss';
@import './GL.scss';

.margin-override {
  @media screen and (max-width: 600px) {
    margin: -70px 0px !important;
  }

  margin: -95px -24px !important;
}
