.bg-grey {
  background-color: #f5f5f5 !important;
}
.rm-padding {
  margin: -8px -12px 0px !important;
  flex: 1;
  width: 100%;
}

.card-padding {
  margin: 0px -4px 0px !important;
  flex: 1;
  width: 100%;
}
