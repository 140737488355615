.core-tr td.table-row-sticky.MuiTableCell-root.MuiTableCell-body {
    position: sticky;
    width: 6em;
    left: 0;
    -webkit-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
    -moz-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
    box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
}

.core-tr td.header-bg.MuiTableCell-root.MuiTableCell-body {
    background-color: #2F53E6;
    color: white;
    font-weight: 500;
}

.core-tr th.MuiTableCell-root.MuiTableCell-body {
    position: absolute;
    width: 6em;
    -webkit-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
    -moz-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
    box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
    border-bottom: none;
    background-color: #FFFFFF;
}

.core-table tr:nth-child(odd) {
    background-color: #E6EEFF;
}

.core-table tr:nth-child(even) {
    background-color: #FFFFFF;
}

.core-tr th:nth-child(even) {
    background-color: #E6EEFF;
}

td.MuiTableCell-root {
    border-bottom: none;
}


.gl-margin {
    margin-top: 60px;
    @media screen and (min-width:600px) {
        margin-top: 125px;
    }
}