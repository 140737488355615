.totalAmountFooter {
  margin-top: 1rem;
  margin-bottom: 53px;
  background-color: orange;
  position: fixed;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 27px;
  z-index: 5;
}

.totalAmountFooterDetail {
  margin-top: 1rem;
  background-color: orange;
  position: fixed;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 27px;
  z-index: 5;
}
