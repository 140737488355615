.project-address {
  text-align: right;
  .MuiIconButton-root {
    padding-top: 0px;
    padding-right: 16px;
    // width: 20px;
  }
}
.contract-budget {
  .MuiListItem-root {
    box-shadow: none;
    padding: 0px;
    margin-bottom: 0px;
    border-bottom: 2px solid #f6f6f6;
    border-radius: 0px;

    .MuiInputBase-input {
      padding: 4px 4px;
      text-align: right;
      font-size: 12px;
      border: 1px solid #0019d8;
      border-radius: 3px;
    }

    .MuiInputBase-root {
      width: 100px;
      margin: 0px 0px 0px auto;
    }

    .right-space {
      width: 100px;
      text-align: right;
    }
  }
}

.contract-retention {
  .MuiListItem-root {
    padding: 12px 0px;
    margin: 0px;
    box-shadow: none;
  }
}

.subcontract-list {
  .MuiListItem-root {
    padding: 12px 0px;
    margin: 0px;
    box-shadow: none;
  }
}
