@import './typography.scss';

.timeline {
  .timeline-header {
    padding: 8px 12px;
    @extend .smTitle;
    background: #e2e2e2;
  }
  &.MuiTimeline-root {
    margin: 0px;
    padding: 0px;
    .MuiTimelineItem-missingOppositeContent:before {
      flex: 0;
    }
    .MuiTimelineContent-root {
      overflow: hidden;
    }
    .MuiTimelineDot-root {
      position: relative;
      margin-top: 15px;
    }
    .MuiTimelineConnector-root {
      background: #e2e2e2;
      margin-top: -10px;
      margin-bottom: -16px;
    }
  }
  .core-list {
    padding-bottom: 0px;
    .MuiListItem-root {
      box-shadow: unset;
      background: none;
      padding: 0px;
      margin-bottom: 12px;
    }
  }
}
