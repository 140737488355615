.overlay {
  position: absolute;
  // height: 224px;
  // width: 100%;
  // top: 42px;
  // right: 20px;
  // bottom: 20px;
  // left: 20px;
  // box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  // border: 1px solid #ffffff;
  // border-radius: 10px;
}

.overlay-wrapper {
  position: absolute;
  //   width: inherit;
  //   top: 20px;
  //   right: 20px;
  //   bottom: 20px;
  //   left: 20px;
}

.card-overlay {
  // position: absolute;
  height: 224px;
  width: 100%;
  box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.card-overlay-wrapper {
  position: absolute;
  width: 320;
  // top: 40px;
  top: 25%;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.camera-mock-vid {
  position: absolute;
  height: 300px;

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
}

.camera-button {
  z-index: 99;
  width: 75%;
  min-width: 100px;
  max-width: 150px;
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: #2716ca;
  border-radius: 5px;
  color: rgb(255, 255, 255);
}

.camera-button-v2 {
  z-index: 99;
  width: 30%;
  min-width: 100px;
  max-width: 150px;
  margin-top: 24px;
  padding: 10px 20px;
  padding-right: 10px;
  text-align: center;
  background-color: #2716ca;
  border-radius: 5px;
  color: rgb(255, 255, 255);
}

.button-wrapper {
  position: absolute;
  margin-top: 300px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.cam-body-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.cam-body-canvas-vid {
  justify-content: center;
  display: flex;
  align-items: center;
  padding-top: 350px;
}
