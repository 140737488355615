@import './layout/body.scss';
@import './layout/authentication.scss';
@import './layout/spacing.scss';
@import './components/button.scss';
@import './components/list.scss';
@import './typography.scss';
@import './components/header.scss';
@import './components/subheader.scss';
@import './components/card.scss';
@import './components/footer.scss';
@import './components/drawer.scss';
@import './components/other.scss';
@import './components/tabs.scss';
@import './components/timeline.scss';
@import './components/camera.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-phone-input-2/lib/material.css';

// @import '~react-quill/dist/quill.snow.css';
@import './components/carousell.scss';
@import './components/dialog.scss';
@import './components/profile.scss';
@import './components/calendar.scss';
@import './components/table.scss';
@import './components/treeview.scss';
@import './components/input.scss';
@import './components/calculator.scss';
@import './components/list.scss';

.w-200p {
  width: 100px;
}
